/// <summary>
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";
    static _API_STATUS_KEY = "status";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";
}

export const _API_BASE_URL = 'https://gateway.huat77.net';
export const _RESOURCE_URL = 'https://www.epay4game.com';

/// <summary>
/// API route
/// </summary>
export class ApiUrl {
    static _API_LAUNCH_GAME = _API_BASE_URL + "/api/player/launch-game";
}

/// <summary>
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
}

export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

export class Language {
    static _CHINESE = "cn";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "bm";
}

export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

export class ValidationPattern {
    static _EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _PHONE_NUMBER_REGEX = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
    static _NUMBER_REGEX = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
    static _DECIMAL_REGEX = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
    static _DIGIT_REGEX = /^\d+$/;
    static _ALPHABETIC_REGEX = /^[a-zA-Z ]+$/;
    static _STANDARD_REGEX = /^[A-Za-z0-9!@#$%^&*()_+}{":?><';,.~ /`|\\]*$/;
    static _IC_NUMBER_REGEX = /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})$/;
    static _PASSPORT_NUMBER_REGEX = /^[A-Za-z]{1,2}[0-9]{6,9}[A-Za-z]{0,1}$/;
    static _ALPHANUMBERIC_REGEX = /^[0-9a-zA-Z ]+$/;
}