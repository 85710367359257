import { InputValidationKey } from './Constant';

/// <summary>
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '';
}

/// <summary>
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

export function getValidationMessage(error, patternErrorTxt = '') {
    var validationMsg = "";
    switch (error.type) {
        case InputValidationKey._REQUIRED_KEY:
            validationMsg = "INPUT_FIELD_REQUIRED";
            break;
        case InputValidationKey._MAX_LENGTH_KEY:
            validationMsg = "MAX_LENGTH_EXCEEDED";
            break;
        case InputValidationKey._MIN_LENGTH_KEY:
            validationMsg = "MIN_LENGTH_NO_MET";
            break;
        case InputValidationKey._MIN_LENGTH_KEY:
            validationMsg = "MIN_LENGTH_NO_MET";
            break;
        case InputValidationKey._MIN_KEY:
            validationMsg = "MIN_ERROR";
            break;
        case InputValidationKey._MAX_KEY:
            validationMsg = "MAX_ERROR";
            break;
        case InputValidationKey._PATTERN:
            validationMsg = !stringIsNullOrEmpty(patternErrorTxt) ? patternErrorTxt : "INVALID_FORMAT";
            break;
        default:
            validationMsg = error.message
            break;
    }
    return validationMsg;
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isObjectEmpty(data) {
    return Object.keys(data).length === 0;
}

export async function imagePathToFileObject(imagePath) {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], imagePath);
    return file;
}

export function formatNumber(data, decimalPlaces) {
    return data.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces })
}

export function arrayGroupByKey(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );

        return result;
    }, {});
};

export function numberWithCurrencyFormat(value, decimal = 2) {
    const CURRENCY_DECIMAL = 2;
    var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;
        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];
            for (let i = processNumber.length - 1; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                }
                else {
                    break;
                }
                decimalDiff--;
            }
            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    return convertedNumber
}

export function ignoreDecimal(e, allowDecimal = false) {
    const _KEYCODE_DECIMAL = 190;
    const _KEYCODE_E = 69;
    let keyCode = e.keyCode;

    if ((keyCode == _KEYCODE_DECIMAL && !allowDecimal) || keyCode == _KEYCODE_E) {
        e.preventDefault();
    }
}

export function generateRandomAlphanumeric(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function range(from, to, step = 1) {
    return [...Array(Math.round((to - from) / step) + 1)].map((_, i) => numberWithCurrencyFormat(from + i * step));
}