import { useState } from 'react';
import './App.css';
import { TextInput, PasswordInput, Button, Group, Grid, Container, LoadingOverlay, Modal, createStyles, Drawer, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons';
import axios from 'axios';
import { ApiUrl, ApiKey, _RESOURCE_URL } from './util/Constant';
import { isObjectEmpty, stringIsNullOrEmpty } from './util/Util';
import OpenApp from "react-open-app";
import { BrandAndroid, BrandApple, Lock, Password } from 'tabler-icons-react';

const useStyles = createStyles((theme, _params, getRef) => ({
	'a': {
		textDecoration: 'none'
	},

	brandInput: {
		input: {
			border: '1px solid #FFD055',
			color: '#fff',
			boxShadow: '1px 2px 10px #330662',
			background: 'linear-gradient(0deg, #8415C9, #400862)',
			borderRadius: theme.spacing.xs,
			padding: '5px 20px',
			height: 47,
			lineHeight: '47px',

			'&:focus': {
				border: '1px solid #FFD055',
			},
		},

		label: {
			marginBottom: 5,
			fontWeight: 400
		}
	},

	brandInputLabel: {
		marginBottom: 5,
		fontWeight: 400
	},

	brandInputWrapper: {
		border: '1px solid #FFD055',
		boxShadow: '1px 2px 10px #330662',
		background: 'linear-gradient(0deg, #8415C9, #400862)',
		borderRadius: theme.spacing.xs,
		height: 47,

		'&:focus': {
			border: '1px solid #FFD055',
		},

		input: {
			padding: '5px 20px',
			color: '#fff',
			height: 47,
			lineHeight: '47px'
		}
	},

	brandButton: {
		border: '1px solid #FFD055',
		background: 'linear-gradient(180deg, #D286FF, #8A19CE, #6E14A5, #480971, #6E14A5, #974DC4A2)',
		fontWeight: 400,
		paddingRight: theme.spacing.xl * 1.5,
		paddingLeft: theme.spacing.xl * 1.5
	},

	launcherDrawer: {
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		background: 'linear-gradient(180deg, #AA54F4, #9E2FDD, #6F13A4)',
		border: '2px solid #FFFFFF33',
		maxWidth: 500,
		margin: '0 auto'
	},

	vendorLogo: {
		width: 30,
		background: 'white',
		borderRadius: 50,
		boxShadow: '0px 8px 10px rgb(0 0 0 / 10%)'
	},

	brandYellowText: {
		color: '#FFD055',
		fontWeight: 'bold'
	},

	launcherDrawerHeader: {
		borderBottom: '2px solid #9035d2',
		boxShadow: '0 2px 0 0 #b462ee',
		padding: 15
	},

	launcherDrawerBody: {
		padding: 10
	},

	closeButton: {
		border: '1px solid #FFD055',
		background: 'linear-gradient(180deg, #D286FF, #8A19CE, #6E14A5, #480971, #6E14A5, #974DC4A2)',
		color: '#fff',
		borderRadius: 50
	},

	launcherDrawerRoot: {
		maxWidth: 500,
		margin: '0 auto'
	},

	btnLogin: {
		a: {
			textDecoration: 'none'
		}
	},

	inputVisibilityToggle: {
		color: "#FFD055",

		":hover": {
			background: '#00000038'
		}
	}
}));

const App = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [playerLaunchGameData, setPlayerLaunchGameData] = useState({});
	const { classes } = useStyles();

	const form = useForm({
		initialValues: {
			username: '',
			password: '',
		},

		validate: (values) => ({
			username: values.username === undefined || values.username == '' ? "Username is required." : null,
			password: values.password === undefined || values.password == '' ? "Password is required." : null
		})
	});

	function getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	}

	const launchGame = async (username, password) => {
		setIsLoading(true);

		try {
			const response = await axios.post(`${ApiUrl._API_LAUNCH_GAME}?username=${username}&password=${password}`);

			if (response[ApiKey._API_STATUS_KEY] == 200) {
				let responseData = response[ApiKey._API_DATA_KEY];
				console.log(responseData);

				if (responseData[ApiKey._API_SUCCESS_KEY]) {
					let launchGameDetail = responseData[ApiKey._API_DATA_KEY];
					setPlayerLaunchGameData(launchGameDetail);

					// if (launchGameDetail['loginUrl'] != null && launchGameDetail['loginUrl'] != '') {
					// 	window.open(launchGameDetail['loginUrl'], '_blank', 'noopener,noreferrer');
					// }
					// else if (getMobileOperatingSystem() == "iOS") {
					// 	window.open(launchGameDetail['appDownloadUrlIos'], '_blank', 'noopener,noreferrer');
					// }
					// else {
					// 	window.open(launchGameDetail['appDownloadUrlAndroid'], '_blank', 'noopener,noreferrer');
					// }
				}
				else {
					throw responseData[ApiKey._API_MESSAGE_KEY];
				}
			}
			else {
				throw 'Server maintainance... Please try again later';
			}
		}
		catch (err) {
			showNotification({
				title: 'Error',
				message: err,
				color: 'red',
				icon: <IconX size={16} />
			})
		}

		setIsLoading(false);
	}

	return (

		<div className="App">
			{/* <LoadingOverlay visible={isLoading} overlayBlur={1} /> */}
			{/* <h1 className="brand-title">ePay H5</h1> */}
			<img src="/images/epayh5.png" className='brand-logo' alt="logo" />

			<form style={{ width: '100%', padding: '0 30px' }} onSubmit={form.onSubmit((values) => launchGame(values.username, values.password))}>
				<TextInput
					mb="md"
					height={45}
					className={classes.brandInput}
					variant='unstyled'
					autoFocus
					label="Player Username"
					placeholder="Player Username"
					{...form.getInputProps('username')}
				/>
				<PasswordInput
					classNames={{
						wrapper: classes.brandInputWrapper,
						visibilityToggle: classes.inputVisibilityToggle,
						label: classes.brandInputLabel
					}}
					variant="unstyled"
					label="Password"
					placeholder="Password"
					{...form.getInputProps('password')}
				/>
				<Group position="center" mt="xl">
					<Button
						variant='filled'
						radius='xl'
						size="md"
						color='violet'
						type="submit"
						loading={isLoading}
						className={classes.brandButton}
					>
						Launch Game
					</Button>
				</Group>
			</form>

			<Drawer
				overlayOpacity={0.55}
				overlayBlur={3}
				opened={!isObjectEmpty(playerLaunchGameData)}
				onClose={() => setPlayerLaunchGameData({})}
				classNames={{
					root: classes.launcherDrawerRoot,
					drawer: classes.launcherDrawer,
					header: classes.launcherDrawerHeader,
					body: classes.launcherDrawerBody,
					closeButton: classes.closeButton
				}}
				title={<Group spacing="sm">
					<img className={classes.vendorLogo} src={`${_RESOURCE_URL}${playerLaunchGameData['imagePath']}`} />
					<Text className={classes.brandYellowText}>{playerLaunchGameData['gameName']}</Text>
				</Group>}
				position='bottom'
			>
				<Stack mx="md">
					<Group position='apart' mb={50}>
						<Stack spacing={0}>
							<Text size="lg" className={classes.brandYellowText}>{playerLaunchGameData['username']}</Text>
							<Text>My Player ID</Text>
						</Stack>
					</Group>
					<Group grow>
						{
							!stringIsNullOrEmpty(playerLaunchGameData['appDownloadUrlAndroid']) &&
							<Button
								radius="xl"
								color='lime'
								style={{ fontSize: 12 }}
								leftIcon={<BrandAndroid size={16} />}
								onClick={() => window.open(playerLaunchGameData['appDownloadUrlAndroid'], '_blank')}
							>
								Download APK
							</Button>
						}
						{
							!stringIsNullOrEmpty(playerLaunchGameData['appDownloadUrlIos']) &&
							<Button
								radius="xl"
								color='dark'
								style={{ fontSize: 12 }}
								leftIcon={<BrandApple size={16} />}
								onClick={() => window.open(playerLaunchGameData['appDownloadUrlIos'], '_blank')}
							>
								Download iOS
							</Button>
						}
					</Group>
					{
						!stringIsNullOrEmpty(playerLaunchGameData['loginUrl']) &&
						<div className={classes.btnLogin}>
							<OpenApp
								href={playerLaunchGameData['loginUrl']}
								android={playerLaunchGameData['loginUrl']}
								ios={playerLaunchGameData['loginUrl']}
							>
								<Button
									color="gray"
									fullWidth
									size="md"
									radius="xl"
									className={classes.brandButton}
								>
									Play Game
								</Button>
							</OpenApp>
						</div>
					}
				</Stack>
			</Drawer>

			<Modal
				size="auto"
				className="launch-game-modal"
				onClose={() => setPlayerLaunchGameData({})}
			>
				<div className="launch-game-container">
					<img style={{ width: 100 }} src={`${_RESOURCE_URL}${playerLaunchGameData['imagePath']}`} />
					<h4>{playerLaunchGameData['gameName']}</h4>
					<p>Username: <b>{playerLaunchGameData['username']}</b></p>
					<div className="content-column content-between">
						{
							!stringIsNullOrEmpty(playerLaunchGameData['loginUrl']) &&
							<OpenApp
								href={playerLaunchGameData['loginUrl']}
								android={playerLaunchGameData['loginUrl']}
								ios={playerLaunchGameData['loginUrl']}
							>
								Launch Game
							</OpenApp>
						}
						{
							!stringIsNullOrEmpty(playerLaunchGameData['appDownloadUrlAndroid']) &&
							<Button onClick={() => window.open(playerLaunchGameData['appDownloadUrlAndroid'], '_blank')}>
								Download Now
							</Button>
						}
						{
							!stringIsNullOrEmpty(playerLaunchGameData['appDownloadUrlIos']) &&
							<Button onClick={() => window.open(playerLaunchGameData['appDownloadUrlIos'], '_blank')}>
								Download Now
							</Button>
						}
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default App;
